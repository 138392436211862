import React from "react";


class City extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick = (city)=>{
    //console.log("city被点击");
    //console.log(city);
    //console.log(this.props.cityCode);
    //console.log(this.props.cityName);
    this.props.onClick(this.props.cityCode,this.props.cityName);
  }

  render() {
    let {index,cityName} = this.props;
    let {onClick} = this;
    return (
        <li key={index}> <a className="city" onClick={onClick} target='_blank'>{cityName}</a> </li>
    );
  }
}

export default City;