import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class CheIngScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    this.state = {
 
      u:u
    };
}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");

  axios.get("/phone/real/random")
  .then(function (response) {
    console.log(response);

  });
}


  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
            <Breadcrumb.Item href={"#/?u="+this.state.u}>
            免费选号
            </Breadcrumb.Item>
 
            <Breadcrumb.Item href={"#/big?u="+this.state.u}>
              特色卡
            </Breadcrumb.Item>
  
            <Breadcrumb.Item href={"#/trade?u="+this.state.u}>
              蹲卡
            </Breadcrumb.Item>

          </Breadcrumb>

          <Card body className="mt-3">好车推荐</Card>

          
        <Card body className="mt-3">加客服13933393509上车</Card>

        <Card body className="mt-3">
            
            <p>电信小水管（正在发车）</p>
            <p> 50元40G限1Mpbs带800分钟</p>
            <p> 全国车 远程上车 不卡年龄</p>
            <p> 车费2XX</p>
           </Card>

           <Card body className="mt-3">
            
            <p>移动小水管（正在发车）</p>
            <p> 39元10G限1Mpbs</p>
            <p> 全国车 远程上车 不卡年龄</p>
            <p> 车费3XX</p>
           </Card>

        <Card body className="mt-3">
            <p>电流卡（候车） </p>
            <p> 39元月享520G 支持百度直连</p>
            <p> 20G通用+500G定向！兔百度系、头条系、腾讯系、网易系、阿里系等。</p>
            <p> 抖音快手腾讯等视频随心刷</p>
            <p> 全国车 远程上车 不卡年龄</p>
          </Card>

          <Card body className="mt-3">
          <p>魔改eSIM 无限卡一变二 （正在发车）</p>
          <p>联通、电信、移动无限卡均可以上车！</p>
          <p>双不限Buff,esim双不限</p>
          <p> 全国车 远程上车</p>
          </Card>

          <Card body className="mt-3">
            <p>嗨钉卡 联通钉钉免流卡 （正在发车）</p>
            <p>33元月享20G通用+600分钟通话+钉钉免流定向双不限</p>
            <p> 全国车 远程上车 不卡年龄</p>
          </Card>

          <Card body className="mt-3">
            <p> 神奇小蛋卡 80G限速 300分钟（候车）</p>
            <p> 全国车 远程上车 不超过28岁</p>
            <p> 超龄的找弟弟妹妹办理，再过户，支持远程过户</p>
            <p> 车费6XX</p>
           </Card>

           
           <Card body className="mt-3">
            
            <p>联通小通卡（需预约）</p>
            <p> 29元40G限1Mpbs带500分钟1主2免费副卡</p>
            <p> 副卡为永久免费副卡</p>
            <p> 全国车 远程上车 不卡年龄</p>
            <p> 车费8XX</p>
           </Card>

          <Card body className="mt-3">
            
            <p>联通山萌卡 无限卡 19元100G限3（正在发车）</p>
            <p> 可破限速  双不限</p>
            <p> 全国车 远程上车 不卡年龄</p>
            <p> 仅限山东省内使用</p>
            <p> 车费3XX</p>
           </Card>

           <Card body className="mt-3">
            <p>联神卡 5G双不限 199元/月</p>
            <p> 联通CPE套餐卡 不限量不限速</p>
            <p> 仅限CPE、随身wifi使用</p>
            <p> 全国车 远程上车 不卡年龄</p>
            <p> 车费1XX</p>
           </Card>

           <Card body className="mt-3">
            <p>5G CPE设备（正在发车）</p>
            <p> 全国包邮</p>
            <p> 车费 1XXX</p>
           </Card>

  

           <Card body className="mt-3">
            
            <p>电信伊利 双下限（需预约）</p>
            <p> 50元月租 双不限 可一拖2</p>
            <p> 支持esim，可再拖3个附属卡，实现1拖5</p>
            <p> 来威海上车</p>
            <p> 车费1XXX</p>
           </Card>

           <Card body className="mt-3">
            
            <p>联通智慧沃家38元档（正在发车）</p>
            <p> 38元双不限 全国远程上车！官宣限7.2Mpbs</p>
            <p> 支持esim</p>
            <p> 全国车 远程上车 不卡年龄</p>
            <p> 车费3XXX</p>
           </Card>

           <Card body className="mt-3">
            
            <p>联通5元米粉卡 无限卡</p>
            <p> 可破限速 不带4好号码</p>
            <p> 支持esim</p>
            <p> 全国车 远程上车 不卡年龄</p>
            <p> 车费8XX</p>
           </Card>
          
 
  

        <Card body className="mt-3">陕沃卡26元125G通用 仅限陕西 发车中 </Card>
        <Card body className="mt-3">安沃卡19元370G通用 仅限安徽 发车中 </Card>
        <Card body className="mt-3">郑沃卡39元270G 仅限郑州 发车中 </Card>
        <Card body className="mt-3">山宝卡26元125G通用 仅限山东 发车中 </Card>
        <Card body className="mt-3">广沃卡29元70G 仅限广东 发车中 </Card>

        <Card body className="mt-3">
          <p>移动小水管38元档（暂停）</p>
          <p>38元月享100G高速流量达量限速不限量，1200分钟通话，1主2免费副卡</p>
          <p>双不限Buff,esim双不限</p>
          <p> 全国车 远程上车 不卡年龄</p>
          </Card>


        <Card body className="mt-3">加客服13933393509上车</Card>

        </Container>
       
    </div>
  }
}

export default CheIngScreen;
