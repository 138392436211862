import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");

  axios.get("/phone/real/random")
  .then(function (response) {
    console.log(response);

  });
}


  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
          <Breadcrumb.Item href="/">
            免费选号
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#/tel29">
           大流量
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#/hot">
              天降神车
            </Breadcrumb.Item>
    

          </Breadcrumb>

          <Card body className="mt-3">下单失败怎么办？</Card>

          <Card body className="mt-3">用产品码下单 尝试不同渠道</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>
            <img src="product/dwk01.png" style={{width:"100%"}}/>
            <img src="product/dwk02.png" style={{width:"100%"}}/>
            <img src="product/dwk03.png" style={{width:"100%"}}/>
           
            </Card>

        <Card body className="mt-3">加客服13933393509解决</Card>

        <Card body className="mt-3">自助尝试解决</Card>

        <Card body className="mt-3">
            身份证号码归属地、手机号归属地、收货地址归属地都不致，参考收货地址写法
          <p>下单号码归属地+归属地详细地址+转快递+真实收货地址</p>
          <p>实际填写不要写+号</p>
         </Card>
        </Container>
       
    </div>
  }
}

export default FAQ;
