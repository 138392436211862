import React from "react";
import City from "./VipCity"


class VipCityList extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    let {onClick} = this.props;
    return this.props.cityList.map((city, index) => {
      //console.log(li)
      return (
          <City key={index} index={index} onClick={onClick} cityCode={city.code} cityName={city.name} />
      );
    });
  }
}

export default VipCityList;