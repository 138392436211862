import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class TradeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    this.state = {
 
      u:u
    };



}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");

  axios.get("/phone/real/random")
  .then(function (response) {
    console.log(response);

  });
}










  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
          <Breadcrumb.Item href={"#/?u="+this.state.u}>
            免费选号
            </Breadcrumb.Item>
            <Breadcrumb.Item href={"#/big?u="+this.state.u}>
            特色卡
            </Breadcrumb.Item>
            <Breadcrumb.Item href={"#/cheing?u="+this.state.u}>
            好车推荐
            </Breadcrumb.Item>
          </Breadcrumb>

          <Card body className="mt-3">无限卡转让与求购</Card>

          <Card body className="mt-3">相中则联系客服搭桥 随时更新</Card>
          <Card body className="mt-3">加客服13933393509 搭桥</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>【出】北京出唐山联通18元低消小水管</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>【收】北京收北京归属地或张家口归属地5元小天神</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>【收】深圳、广州收西钉</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】浙江温州收畅玩畅视</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【出】许昌出河南许昌归属地易学卡，月租29元，定向双不限+2G多通用，带0元5G圣代包享500Mpbs速率，支持百度直连</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【出】河南出河南沃派阿里系免流卡</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【出】新乡出新乡电信永久49，1000国内主叫，40g流量降速，4张0元副卡，和千兆宽带！</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】深圳收畅玩卡</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】深圳收易学、新刘邦  ，手拉手</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】浙江收刘邦</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】兰州或者白银 收 甘肃白银归属地 云圣卡或者宝宝卡 预算2k到3k</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】河北收1张电信39元星卡步步高日租版200g定向流量超出1元1g</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】广东佛山收无限卡</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【出】青岛出河北畅享，29月租，40限7 100限1！</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】浙江杭州收官宣双不限</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】新乡收导学易学流邦百度直连卡</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】潮汕收沃派畅玩玩卡</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【出】贵阳出导学易学卡  定向双不限 带500速率 月租29/月！</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】江西南昌收内蒙古超然</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】收保定的沃家，38，58，98都可以</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【出】河南新乡出江西南昌阿里宝宝卡，9元月租+3元任性用日租宝！不限量不限速！可玩免流双不限，也可玩通用双不限！号称不怕翻车的9元定向双不限神卡！</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】收广西桂林保定云圣或宝宝卡</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】浙江台州收无限卡</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】沈阳收刘邦易学</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】北京移动4g 588尊享无限套餐</Card>
<Card body className="mt-3"  style={{textAlign:"left"}}>【收】山西地区收 一张老西钉,极速（vvip）,尊享老西钉,尊享老西钉vvip​​​​</Card>



          <Card body className="mt-3"  style={{textAlign:"left"}}>【出】江西宜春袁州区出畅越低消，幸福家家乐+五福卡！双不限！!转让价3XXX </Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>【出】福建出张嫦娥卡，5元15限1，400分钟300短信，转让费1XXX！人在福州，福建省内都可以交易!</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>【出】上海浦东出上海联通靓号百度云圣卡，XXX-ABBB-CDDD双豹子号码，百度云圣卡套餐，30元基本月租+3元不限量不限速（无限速服务），30元包含2G流量+400分钟通话+每月百度网盘会员+500速率，可免流双不限不激活3元不限量每月30元月租，浦东手拉手，价格2XXXX元</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}> 【出】上海浦东出阿里宝宝卡一主一附属卡！官宣双不限！安徽六安归属地阿里宝宝卡，号码不带4，带0元5G圣代包500速率，有附卡一张不保证可以带附卡过户，浦东手拉手，价格6XXX元!</Card>     
          <Card body className="mt-3"  style={{textAlign:"left"}}>【出】济南出大黑牛 6XX ！</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>【出】济南出刘邦 5XX ！带0元5G优享服务圣代包！ </Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>【收】广州收定向双不限卡，易学刘邦沃派大黑牛都可以</Card>
 


 
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】新疆乌鲁木齐昌吉收刘邦导学 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】四川达州出易学定向双不限！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】山东济南收定向双不限卡，要求支持百度直连！易学、刘邦、沃派免流卡等都可以！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】福建泉州出导学卡和超燃2 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】福建收导学 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】江苏收定向双不限卡！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】北京出畅享无限卡一张 能来丰台的可以上车！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】收张河南新乡不限量套餐卡 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】石家庄收19月租白金牛 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】天津收河南沃派！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】广东收大黑牛一枚 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】石家庄收张畅享卡 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】河北收伊利卡一枚 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】石家庄收一张伊利专享卡，双不限 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】青海收个刘邦、易学，定向双不限！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】湖北十堰收双不限卡 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】南昌收19芝麻，19d！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】南京出沃派阿里系免流卡，定向双不限，支持百度直连！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】河南郑州 收 沃派阿里系免流卡，定向双不限！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】湖南长沙手拉手出河南沃派阿里系免流卡，1XX带125元话费！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】沈阳出易学定向双不限 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】深圳出18年老西钉！官宣双不限！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】孝感收刘邦或易学！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】山西吕梁出兵芯卡，16月租15限1 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】山西吕梁出新刘邦，19月租定向双不限 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】郑州出百度云圣卡 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】南京收个双不限 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】河南新乡 收 河南联通冰淇淋 或者3折卡 电信3折畅享  不限量的卡 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】天津出河南沃派，是39月租，返费10元10个月月租29，有百度阿里定向双不限流量包三年，支持百度直连 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】收广州0➕3或者➕10或者0+50 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】合肥收个易学，定向双不限！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】郑州出导学定向双不限 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】贵州收刘邦卡！定向双不限卡！  </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】济南出爽卡！29元180G限1 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】广州番禺出易学卡.流邦卡！定向双不限，支持百度直连！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】驻马店或者郑州归属地398五折冰激凌，套餐全名叫畅爽全国冰激凌套餐-398/月 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】广东珠海出河南沃派阿里系免流卡，定向双不限，支持百度直连！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】威海手拉手出电信双不限！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【收】郑州收流邦一枚，定向双不限卡！ </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】江苏扬州出河南沃派定向双不限 支持百度直连 带500Mpbs 车费 xx元甩了甩了,话费177元另外算 </Card>
 <Card body className="mt-3"  style={{textAlign:"left"}}>【出】成都出北京归属地新刘邦 </Card>

            <Card body className="mt-3"  style={{textAlign:"left"}}>
            <p>百度云圣卡转让</p>
            <p>特色：30元月租 百度系定向双不限 带3元任性用日租包</p>
            <p>交易地区：郑州</p>
            <p>一口价：3XXX</p>
            </Card>

            <Card body className="mt-3"  style={{textAlign:"left"}}>
            <p>切糕转让</p>
            <p>特色：19元月租 无限流量小水管</p>
            <p>交易地区：上海</p>
            <p>一口价：8XX</p>
            </Card>

            <Card body className="mt-3"  style={{textAlign:"left"}}>
            <p>易学转让</p>
            <p>特色：29元月租</p>
            <p>交易地区：吉安</p>
            <p>一口价：5XX</p>
            </Card>

          <Card body className="mt-3"  style={{textAlign:"left"}}>
            <p>1拖4磅礴卡转让</p>
            <p>归属地：北京</p>
            <p>资费：46元+4张10元副卡，已开通SA，全部双不限</p>
            <p>交易地区：北京</p>
            <p>一口价：2k</p>
            <p>风险：磅礴卡双不限非官宣，过户又可能触发限速。</p>
            <p>联系方式：联系客服索取</p>
            </Card>


            <Card body className="mt-3"  style={{textAlign:"left"}}>
            <p>联通畅享转让</p>
            <p>交易地区：北京</p>
            <p>一口价：700</p>
            </Card>

        <Card body className="mt-3">发布转让或求购信息，咨询客服</Card>

        <Card body className="mt-3">客服13933393509</Card>
        </Container>
       
    </div>
  }
}

export default TradeScreen;
