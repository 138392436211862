import React from "react";
import { Card } from 'react-bootstrap';


class QueryNumber extends React.Component {
  constructor(props) {
    super(props);
  
  }

  onClick = ()=>{
    //console.log("city被点击");
    //console.log(city);
    //console.log(this.props.cityCode);
    //console.log(this.props.cityName);
    this.props.onClick(this.props.orderNo,this.props.phoneNumber);
  }

  render() {
    let {index,phoneNumber} = this.props;
    let {onClick} = this;
    return (
        <Card.Text key={index}> <a className="city" onClick={onClick} target='_blank'>{phoneNumber}</a> </Card.Text>
    );
  }
}

export default QueryNumber;