import React from "react";
import Product from "./Channel"


class ChannelList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            channelList:[
              {channelCode:'shenzhen',channelName:'通道一'},
              {channelCode:'beijing',channelName:'通道二'},
              {channelCode:'fujian',channelName:'通道三'} ,
              {channelCode:'shantou',channelName:'通道四'}      
            ]
        }
    
    
    };



    render() {
      let {onClick} = this.props;
      return this.state.channelList.map((model, index) => {
                    return   <Product key={index}  onClick={onClick} channelCode={model.channelCode}  channelName={model.channelName} />
                        
                });

    }
  }
  
  export default ChannelList;