import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import HomeScreen from './HomeScreen';
import VipScreen from './VipScreen';
import FreeScreen from './FreeScreen';
import FreeRechargeScreen from './FreeRechargeScreen';
import Free10000Screen from './Free10000Screen';
import TradeScreen from './TradeScreen'; 
import ProductZhiShi from './ProductZhiShi'; 
import FreeHotScreen from './FreeHotScreen'; 
import FreeHot01Screen from './FreeHot01Screen'; 
import FreeHot02Screen from './FreeHot02Screen'; 
import FreeHot03Screen from './FreeHot03Screen'; 

import FAQ from './FAQ'; 
import FreeCheScreen from './FreeCheScreen'; 
import CheIngScreen from './CheIngScreen'; 
import FlexProduct from './FlexProduct'; 
import QueryScreen from './QueryScreen'; 
import BigProductScreen from './BigProductScreen'; 
import QueryBigScreen from './QueryBigScreen'; 
import FlexProductPay from './FlexProductPay'; 
import UnicomScreen from './UnicomScreen'; 
import NotFoundScreen from './NotFoundScreen'; 
import KAJoinScreen from './KAJoinScreen'; 
import FlexProductAlipayScreen from './FlexProductAlipayScreen'; 
import UnicomProductScreen from './UnicomProductScreen'; 

 

class App extends React.Component {
 
  render() {
     
     

      return <div>
                <HashRouter>
                    <Switch>
                        <Route exact path="/" component={HomeScreen}/>
                        <Route exact path="/vip" component={VipScreen}/>
                        <Route exact path="/free" component={FreeScreen}/>
                        <Route exact path="/recharge" component={FreeRechargeScreen}/>
                        <Route exact path="/trade" component={TradeScreen}/>
                        <Route exact path="/free10000" component={Free10000Screen}/>
                        <Route exact path="/zhishi" component={ProductZhiShi}/>
                        <Route exact path="/faq" component={FAQ}/>
                        <Route exact path="/hot" component={FreeHotScreen}/>
                        <Route exact path="/hot01" component={FreeHot01Screen}/>
                        <Route exact path="/hot02" component={FreeHot02Screen}/>
                        <Route exact path="/hot03" component={FreeHot03Screen}/>

        
                        <Route exact path="/che" component={FreeCheScreen}/>
          
                        <Route exact path="/cheing" component={CheIngScreen}/>
                        
                        <Route exact path="/query" component={QueryScreen}/>

                        <Route exact path="/big" component={BigProductScreen}/>

                        <Route exact path="/cha" component={QueryBigScreen}/>

                        <Route exact path="/joinka" component={KAJoinScreen}/>
                        

                        <Route  path="/product/:id/:u" component={FlexProduct} />
                        <Route  path="/pay/:ordercode/:token" component={FlexProductPay} />
                        <Route  path="/alipay/:ordercode/:token" component={FlexProductAlipayScreen} />

                        
                        <Route  path="/lt/:productcode/:u" component={UnicomScreen} />

                        <Route  path="/ltproduct/:vcode/:newphone/:provincecode/:citycode/:u" component={UnicomProductScreen} />

                        <Route path="*"   component={NotFoundScreen} />
                        
                        
                    </Switch>
               </HashRouter>
             </div>
      }
}

export default App;
