import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';
import NumberList from './NumberList';
import CityLetterList from './CityLetterList';
import ProductList from './ProductList';
import ChannelList from './ChannelList';
import Url from 'url';

class UnicomScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickCount: 0,numbers:[],hasNo4:true,
      has3A:false,
      has4A:false,
      hasAABB:false,
      hasABAB:false,
      hasABAC:false,
      hasABC:false,
      has8Limit:false,
      has4Limit:false,
      totalCount:0,
      findCount:0,counter:0,
      showCity:false,
      showProduct:false,
      cityName:'广州',
      cityCode: 510,
      scanRun:false,
      goodsId:'982112279603',
      productName:'大王卡2.0',
      aabbList:[],
      ababList:[],
      abacList:[],
      abcList:[],
      showChannel:false,
      channelName:'通道三',
      channelCode:'fujian',
      cacheChannelCode:'',
      u:'zuime',
      showCityText:true
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeHasNo4 = this.onChangeHasNo4.bind(this);
    this.onChangeHas3A = this.onChangeHas3A.bind(this);
    this.onChangeHas4A = this.onChangeHas4A.bind(this);
    this.onChangeHasAABB= this.onChangeHasAABB.bind(this);
    this.onChangeHasABAB= this.onChangeHasABAB.bind(this);
    this.onChangeHasABAC= this.onChangeHasABAC.bind(this);
    this.onChangeHasABC= this.onChangeHasABC.bind(this);
    this.onCityClick = this.onCityClick.bind(this);
    this.onProductClick = this.onProductClick.bind(this);
    this.onChooseCityClick = this.onChooseCityClick.bind(this);
    this.onChooseProductClick = this.onChooseProductClick.bind(this);
    this.onChooseChannelClick = this.onChooseChannelClick.bind(this);
    this.onStopClick = this.onStopClick.bind(this);
    this.job =null;
     
    console.log(window);
    if(window.location.hostname!=="localhost"){
      //hao.zhongdaiqi.com ->  hmb.zhongdaiqi.com
      if(window.location.hostname==="hao.zhongdaiqi.com"){
          //确保是https
          if(window.location.protocol==="http:"){
            console.log("自动跳转到https 确保用户数据安全");  
            window.location.href=window.location.href.replace("http://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }else{
            window.location.href=window.location.href.replace("https://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }
      }

      //确保是https
      if(window.location.protocol==="http:"){
        console.log("自动跳转到https 确保用户数据安全");  
        window.location.href=window.location.href.replace("http://","https://")
      }
    }
}

 // 页面加在完成
 componentDidMount(){

  console.log("智能追踪推广员");
  let finder = Url.parse(this.props.location.search,true); 
  var that = this;
  console.log(finder.query);
  if(finder.query.u){
    console.log("推荐员信息存在，重置默认推广员");
    this.setState({u:finder.query.u});
    //推广员默认城市
    if(finder.query.u==='Apathy'){
      this.setState({cityName:'沧州',cityCode:'317'});
    }
    try {
        //写入本地永久缓存
        localStorage.setItem("u",finder.query.u);//写入本地 追踪标
    }
    catch(err) {
      console.log("写入本地追踪标出现了异常");
    }
    
  }else{
    
    try {
      //尝试读取本地追踪标
      var local_u = localStorage.getItem("u");//获取存储的元素
      if(local_u){
        console.log("本地缓存有追踪标，重置默认推广员");
        that.setState({u:local_u});
      }
    }
    catch(err) {
      console.log("读取追踪标出现了异常");
    }
  }
  //默认城市
  
 

  var product = this.filterProduct(this.props.match.params.productcode);
  console.log("读取到产品");
  console.log(product);
  //产品重定向

  that.setState({ 
    goodsId:product[0].goodsIdA,
    productName:product[0].productName,
    tips:product[0].tips
  });

  //默认城市
  console.log("是否修正默认归属地")
  //广东的流量王默认使用汕头归属地
  if( product[0].productName.indexOf("广东流量王")===-1 
  && product[0].productName.indexOf("深圳流量王超享版")===-1
  && product[0].productName.indexOf("联通流量王超享版") ===-1
  && product[0].productName.indexOf("联通王卡") ===-1
  && product[0].productName.indexOf("飞浪卡") ===-1
  ){
    axios.get( "/v2/api/ipBelong" )
    .then(function (response) {
        console.log("正在修正默认归属地")
        console.log(response)
        if(response.data.success){
          that.setState({
            cityName:response.data.data.cityName,
            cityCode: response.data.data.cityCode
          })

        }

    });
  }

  if(  product[0].productName.indexOf("飞浪卡") >-1 ){
    console.log("默认不让选归属地")
    that.setState({
      showCityText:false
    })
  }

  if(product[0].productName.indexOf("联通流量王超享版") >-1 
  || product[0].productName.indexOf("深圳流量王超享版") >-1
  || product[0].productName.indexOf("联通王卡") >-1
  || product[0].productName.indexOf("联通飞浪卡") >-1
  ){
    console.log("默认固定深圳归属地")
    that.setState({
      cityName: "深圳",
      cityCode:  540,
      showCityText:false
    })
  }
  
  if( product[0].productName.indexOf("联通飞浪卡59元") >-1 ){
    console.log("默认固定佛山联通归属地")
    that.setState({
      cityName: "佛山",
      cityCode:  530,
      showCityText:false
    })
  }

  if( product[0].productName.indexOf("佛山联通王卡") >-1 ){
    console.log("默认固定佛山联通归属地")
    that.setState({
      cityName: "佛山",
      cityCode:  530,
      showCityText:false
    })
  }

  
}

filterProduct = (productCode)=>{
      var  productList = [
        {goodsId:'981701127085',goodsIdA:'982112279603',productName:'阿里钉钉商务银卡（收货激活改套餐上车）',tips:"钉钉无限免流！下单为王卡，收货激活后改套餐为阿里阿里钉钉商务银卡！卡友试车成功的城市：北京、汕头、唐山、深圳、广州、银川、广西、湖北襄阳、苏州，昆山，南京、四川、江西、泉州、安徽、贵州。几乎全国免费车，如有遗漏，可联系客服补充！下单优先选自己所在归属地！"},
       
        {goodsId:'512209166030',goodsIdA:'512209166030',productName:'飞浪卡',tips:"59元月享200G通用+200分钟！长期套餐！首充100元套餐生效！支持2张副卡！随时停办，从速！"},
        {goodsId:'512209166030sz',goodsIdA:'512209166030',productName:'联通飞浪卡',tips:"59元月享200G通用+200分钟！长期套餐！首充100元套餐生效！支持2张副卡！随时停办，从速！"},

        {goodsId:'512209166030fs',goodsIdA:'512209166030',productName:'联通飞浪卡59元',tips:"59元月享200G通用+200分钟！长期套餐！首充100元套餐生效！支持2张副卡！随时停办，从速！"},


        {goodsId:'512206171407',goodsIdA:'512206171407',productName:'佛山联通王卡',tips:"佛山联通王卡 29元30G定向！连号、3A、AABB、情侣号非常多!可向客服索取60w号库文件自助选号！"},


        {goodsId:'982012230462',goodsIdA:'982012230462',productName:'宝卡PRO',tips:"29元 5GB+30GB免流"},
        {goodsId:'982101061332',goodsIdA:'982101061332',productName:'小龙卡',tips:"35元 500分钟+5GB+30G腾讯+10G头条+10G快手+深报免流"},
        {goodsId:'982101061334',goodsIdA:'982101061334',productName:'大龙卡',tips:"53元 800分钟+4GB+30G腾讯+10G头条+10G快手+深报免流"},
        {goodsId:'982101061335',goodsIdA:'982101061335',productName:'天龙卡',tips:"77元 1200分钟+6GB+30G腾讯+10G头条+10G快手+深报免流"},
        {goodsId:'982112279603',goodsIdA:'982112279603',productName:'阿里钉钉商务银卡（收货激活改套餐上车）',tips:"钉钉无限免流！下单为王卡，收货激活后改套餐为阿里阿里钉钉商务银卡！卡友试车成功的城市：北京、汕头、唐山、深圳、广州、银川、广西、湖北襄阳、苏州，昆山，南京、四川、江西、泉州、安徽、贵州。几乎全国免费车，如有遗漏，可联系客服补充！下单优先选自己所在归属地！"},
        {goodsId:'982112249498',goodsIdA:'982112249498',productName:'阿里宝卡轻享版',tips:"29元 30GB免流"},
        {goodsId:'982101182332',goodsIdA:'982101182332',productName:'联通语音王48',tips:"48元 800分钟 3GB 200条短信"},
        {goodsId:'982012240498',goodsIdA:'982012240498',productName:'联通语音王88',tips:"88元 3000分钟 8GB"},
        {goodsId:'982012240497',goodsIdA:'982012240497',productName:'联通语音王68',tips:"68元 2000分钟 6GB"},

        {goodsId:'512205138781',goodsIdA:'982112279603',productName:'广东流量王超享版',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版腾讯版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},
        {goodsId:'512205138782',goodsIdA:'982112279603',productName:'广东流量王超享版（阿里版）',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版阿里版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},
        {goodsId:'512205138783',goodsIdA:'982112279603',productName:'广东流量王超享版（头条版）',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版头条版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},
        
        {goodsId:'512204287696',goodsIdA:'982112279603',productName:'联通流量王超享版',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版腾讯版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},
     

        {goodsId:'512207052366',goodsIdA:'982112279603',productName:'深圳流量王超享版',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版腾讯版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},
        {goodsId:'512207052369',goodsIdA:'982112279603',productName:'深圳流量王超享版（阿里版',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版阿里版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},
        {goodsId:'512207052367',goodsIdA:'982112279603',productName:'深圳流量王超享版（头条版）',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版头条版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},

        {goodsId:'512207072584',goodsIdA:'512207072584',productName:'联通王卡',tips:"39元月租 首充50得100 20G通用+30G定向！ 如下单失败，请联系底部客服排查问题。"},



        
        {goodsId:'512204287744',goodsIdA:'982112279603',productName:'广东流量王优享版',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版腾讯版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},
        {goodsId:'512204287743',goodsIdA:'982112279603',productName:'广东流量王优享版（阿里版）',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版阿里版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},
        {goodsId:'512204267592',goodsIdA:'982112279603',productName:'广东流量王优享版（头条版）',tips:"请选广州归属地，收货激活后，拨打02010010，转客服 说要改成40元包60g通用+30g定向的套餐(流量王超享版头条版)，不需要说其他的，就说要改套餐！如改套餐失败就销户，也没有什么损失。"},
        

 
        {goodsId:'982112148754',goodsIdA:'982112148754',productName:'大驿Pro',tips:"59元 1500分钟+5G通用+30G定向"},
        {goodsId:'982007162554',goodsIdA:'982007162554',productName:'小驿卡',tips:"39元 800分钟+30G定向"},
        {goodsId:'981808163933',goodsIdA:'981808163933',productName:'大乐卡',tips:"39元 500分钟+1G通用"},

 
        {goodsId:'982007232937',goodsIdA:'982007232937',productName:'孝心卡',tips:"19元月租 2G通用+10G定向+100分钟+3个亲情号"},
        {goodsId:'982006231233',goodsIdA:'982006231233',productName:'亲子卡',tips:"19元月租 3GB国内流量+100分钟"},
        {goodsId:'982208154208',goodsIdA:'982208154208',productName:'百城奋斗卡',tips:"百城奋斗卡！接到通知已经下线，再下单可能成功也可能失败，风险自担！"},
        {goodsId:'982208154228',goodsIdA:'982208154228',productName:'全民奋斗卡',tips:"全民奋斗卡！接到通知已经下线，再下单可能成功也可能失败，风险自担！"},
        {goodsId:'982208164241',goodsIdA:'982208164241',productName:'国民奋斗卡',tips:"国民奋斗卡！接到通知已经下线，再下单可能成功也可能失败，风险自担！"},
        
        
    ]

   return productList.filter((item)=>{
    console.log(item)
    return item.goodsId.indexOf(productCode)>-1
   });
}

filterKeyNumber = (nList,keyNumber)=>{
  console.log("keyNumber:"+keyNumber.value);
  if(keyNumber.value===''){
    return nList;
  }
  return nList.filter((item)=>{
    console.log("keyNumber.value:"+item.serialNumber.indexOf(keyNumber.value)>-1)
    return item.serialNumber.indexOf(keyNumber.value)>-1
  })
}

filterNo4 = (nList,runFilter)=>{
  if(!runFilter){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  return nList.filter((item)=>{return item.serialNumber.indexOf(4)===-1})
}

filter3A = (nList,runFilter)=>{
  if(!runFilter){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  return nList.filter((item)=>{ 
        return item.serialNumber.indexOf('111')>-1
      ||item.serialNumber.indexOf('222')>-1
      ||item.serialNumber.indexOf('333')>-1
      ||item.serialNumber.indexOf('444')>-1
      ||item.serialNumber.indexOf('555')>-1
      ||item.serialNumber.indexOf('666')>-1
      ||item.serialNumber.indexOf('777')>-1
      ||item.serialNumber.indexOf('888')>-1
      ||item.serialNumber.indexOf('999')>-1
      ||item.serialNumber.indexOf('000')>-1}
  )
}

filter4A = (nList,runFilter)=>{
  if(!runFilter){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  return nList.filter((item)=>{ 
        return item.serialNumber.indexOf('1111')>-1
      ||item.serialNumber.indexOf('2222')>-1
      ||item.serialNumber.indexOf('3333')>-1
      ||item.serialNumber.indexOf('4444')>-1
      ||item.serialNumber.indexOf('5555')>-1
      ||item.serialNumber.indexOf('6666')>-1
      ||item.serialNumber.indexOf('7777')>-1
      ||item.serialNumber.indexOf('8888')>-1
      ||item.serialNumber.indexOf('9999')>-1
      ||item.serialNumber.indexOf('0000')>-1}
  )
}

filterAABB = (nList,context)=>{
  if(!context.hasAABB){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  
  var checkAABBList = this.getAABBList();
  console.log("AABBList begin");
  console.log(checkAABBList)
  console.log("AABBList end");
  return nList.filter((item)=>{ 
    return checkAABBList.filter((xAABB)=>{
      if(context.has8Limit){
        return item.serialNumber.substr(3,8).indexOf(xAABB) >-1
      }else if(context.has4Limit){
        return item.serialNumber.substr(7,4).indexOf(xAABB) >-1
      }else{
        return item.serialNumber.indexOf(xAABB) >-1
      }

    }).length>0;
       
   })
}

getAABBList = ()=>{
  if(this.state.aabbList.length!==0){
    return this.state.aabbList;
  }
  var aabbList = [];
  for(var i=0;i<9;i++){
    for(var j=0;j<9;j++){
      aabbList.push(i+''+i+''+j+''+j);
    }
  }
  this.setState({
    aabbList:aabbList

  });
  return aabbList;
}

filterABAB = (nList,context)=>{
  console.log("hmb");
  if(!context.hasABAB){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  
  var checkABABList = this.getABABList();
  console.log("checkABABList begin");
  console.log(checkABABList)
  console.log("checkABABList end");
  return nList.filter((item)=>{ 
     return checkABABList.filter((xABAB)=>{
      if(context.has8Limit){
        return item.serialNumber.substr(3,8).indexOf(xABAB) >-1
      }else if(context.has4Limit){
        return item.serialNumber.substr(7,4).indexOf(xABAB) >-1
      }else{
        return item.serialNumber.indexOf(xABAB) >-1
      }
     }).length>0;
        
    })
}

getABABList = ()=>{
  if(this.state.ababList.length!==0){
    return this.state.ababList;
  }
  var ababList = [];
  for(var i=0;i<9;i++){
    for(var j=0;j<9;j++){
      ababList.push(i+''+j+''+i+''+j);
    }
  }
  this.setState({
    ababList:ababList

  });
  return ababList;
}


filterABAC = (nList,context)=>{
  if(!context.hasABAC){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  
  var checkABACList = this.getABACList();
  console.log("checkABACList begin");
  console.log(checkABACList)
  console.log("checkABACList end");
  return nList.filter((item)=>{ 
     return checkABACList.filter((xABAC)=>{
      if(context.has8Limit){
        return item.serialNumber.substr(3,8).indexOf(xABAC) >-1
      }else if(context.has4Limit){
        return item.serialNumber.substr(7,4).indexOf(xABAC) >-1
      }else{
        return item.serialNumber.indexOf(xABAC) >-1
      }
     }).length>0;
        
    })
}

getABACList = ()=>{
  if(this.state.abacList.length!==0){
    return this.state.abacList;
  }
  var abacList = [];
  for(var i=0;i<9;i++){
    for(var j=0;j<9;j++){
      for(var m=0;m<9;m++){
        abacList.push(i+''+j+''+i+''+m);
      }
    }
  }
  this.setState({
    abacList:abacList

  });
  return abacList;
}


filterABC = (nList,context)=>{
  if(!context.hasABC){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  
  var checkABCList = this.getABCList();
  console.log("checkABCList begin");
  console.log(checkABCList)
  console.log("checkABCList end");
  return nList.filter((item)=>{ 
     return checkABCList.filter((xABC)=>{
      if(context.has8Limit){
        return item.serialNumber.substr(3,8).indexOf(xABC) >-1
      }else if(context.has4Limit){
        return item.serialNumber.substr(7,4).indexOf(xABC) >-1
      }else{
        return item.serialNumber.indexOf(xABC) >-1
      }
     }).length>0;
        
    })
}

getABCList = ()=>{
  if(this.state.abcList.length!==0){
    return this.state.abcList;
  }
  var abcList = [];
  for(var i=0;i<7;i++){
    abcList.push(i+''+(i+1)+''+(i+2));
  }
  for(var i=9;i>3;i--){
    abcList.push(i+''+(i-1)+''+(i-2));
  }
  this.setState({
    abcList:abcList

  });
  return abcList;
}

chooseUrl = (number,city,goodsId,that)=>{
  if(number.length>4){
      console.log("超过4位，无需传数字")
      return "v2/"+that.state.channelCode+"/phoneNum/select?belongCity="+city+'&goodsId='+goodsId;
  }
  console.log("4位以内，需要摆动")
  console.log("counter:"+ that.state.counter % 2);
  that.setState( {counter:that.state.counter+1 })
  if(that.state.counter % 2 === 1){
    return "v2/"+that.chooseChannelCode() + "/phoneNum/select?belongCity="+city+"&keyNum="+number.value+'&goodsId='+goodsId;
  }
  return "v2/"+ that.chooseChannelCode()+"/phoneNum/select?belongCity="+city+'&goodsId='+goodsId;
}

chooseChannelCode = ()=>{
  if("菜鸟宝卡mini,菜鸟大宝,菜鸟小宝,联通语音王48,联通语音王68,联通语音王88,小龙卡,天龙卡,大龙卡".indexOf(this.state.productName)>-1){
    if("深圳".indexOf(this.state.cityName)>-1){
      return "shenzhen";
     }
      
    return "shantou";
  }

  
  if(this.state.productName.indexOf("大乐卡")>-1){
    if("北京".indexOf(this.state.cityName)>-1){
      return "beijing";
    }
    return "shantou";
  }

  if(this.state.productName.indexOf("广东流量王优享版")>-1){
    return "shantou";
  }

  if(this.state.productName.indexOf("广东流量王超享版")>-1){
    return "shantou";
  }

  if(this.state.productName.indexOf("联通流量王")>-1){
    return "shenzhen";
  }

  if(this.state.productName.indexOf("深圳流量王超享版")>-1){
    return "shenzhen";
  }


  if("佛山".indexOf(this.state.cityName)>-1){
    return "foshan";
   }


  if("深圳".indexOf(this.state.cityName)>-1){
    return "shenzhen";
   }

 

      if("国民奋斗卡".indexOf(this.state.productName)>-1){
        return "shantou";
      }

      if("全民奋斗卡".indexOf(this.state.productName)>-1){
        return "shantou";
      }

      if("百城奋斗卡".indexOf(this.state.productName)>-1){
        return "shantou";
      }
      if("深圳".indexOf(this.state.cityName)>-1){
        return "shenzhen";
      }
      if("福州,厦门,莆田,三明,泉州,漳州,南平,龙岩,宁德".indexOf(this.state.cityName)>-1){
        return "fujian";
      }

     if("北京".indexOf(this.state.cityName)>-1){
       return "beijing";
     }


     if("广州,深圳,珠海,汕头,佛山,韶关,湛江,肇庆,江门,茂名,惠州,梅州,汕尾,河源,阳江,清远,东莞,中山,潮州,揭阳,云浮".indexOf(this.state.cityName)>-1){
      return "shantou";
     }
    //北京与福建摇摆 50%分流
     //需要确保单次扫号期间不能随意变化
     if(this.state.cacheChannelCode){
        return this.state.cacheChannelCode;
     }
     let rndChannelCode = ["fujian","beijing"][Math.round(Math.random())];
     this.setState({cacheChannelCode: rndChannelCode});
     return rndChannelCode;
}

onStopClick = (event)=>{
  event.preventDefault() //阻止表单提交
  this.setState({
    scanRun:false
  });
  if(this.job!=null){
    clearInterval(this.job);
  }

}
handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  var that = this
  console.log("hasNo4 value:"+ this.state.hasNo4);
  console.log("has3A value:"+ this.state.has3A);

  if("广东流量王优享版".indexOf(this.state.productName)>-1){
    if("广州,深圳,珠海,汕头,佛山,韶关,湛江,肇庆,江门,茂名,惠州,梅州,汕尾,河源,阳江,清远,东莞,中山,潮州,揭阳,云浮".indexOf(this.state.cityName)===-1){
      alert("广东流量王仅限广东");
      return;
     }
  }

  if("联通语音王48,联通语音王68,联通语音王88".indexOf(this.state.productName)>-1){
    if("广州,深圳,珠海,汕头,佛山,韶关,湛江,肇庆,江门,茂名,惠州,梅州,汕尾,河源,阳江,清远,东莞,中山,潮州,揭阳,云浮".indexOf(this.state.cityName)===-1){
      alert("联通语音王仅限广东归属地");
      return;
     }
  }

  if("菜鸟宝卡mini,菜鸟大宝,菜鸟小宝".indexOf(this.state.productName)>-1){
    if("广州,深圳,珠海,汕头,佛山,韶关,湛江,肇庆,江门,茂名,惠州,梅州,汕尾,河源,阳江,清远,东莞,中山,潮州,揭阳,云浮".indexOf(this.state.cityName)===-1){
      alert("宝卡仅限广东归属地");
      return;
     }
  }

  console.log(this.state.productName);
  console.log("广东流量王超享版".indexOf(this.state.productName));
  console.log(this.state.productName.indexOf("广东流量王超享版"));
  if(this.state.productName.indexOf("广东流量王超享版")>-1){
    if("广州,深圳,珠海,汕头,佛山,韶关,湛江,肇庆,江门,茂名,惠州,梅州,汕尾,河源,阳江,清远,东莞,中山,潮州,揭阳,云浮".indexOf(this.state.cityName)===-1){
      alert("广东流量王仅限广东归属地");
      return;
     }
  }
 
  if("深圳流量王超享版".indexOf(this.state.productName)>-1){
    if("深圳".indexOf(this.state.cityName)===-1){
      alert("深圳流量王仅限深圳归属地");
      return;
     }
  }


  that.setState({
    scanRun:true
  });
  if(this.job!=null){
    clearInterval(this.job);
    that.setState({
      clickCount: 0,numbers:[],totalCount:0,
      findCount:0,counter:0,
      showCity:false
    });
  }
  this.job = setInterval(() => {
    axios.get( that.chooseUrl(number,that.state.cityCode,that.state.goodsId,that) )
    .then(function (response) {
        console.log("request done");
        if(response.data.success ){
          console.log(response.data.data.randomList);
          that.setState({
            totalCount: that.state.totalCount+response.data.data.randomList.length
          })
          var  finalNumbers = that.filterKeyNumber(response.data.data.randomList,number);
          console.log("finalNumbers===============Begin:");
          console.log(finalNumbers);
          console.log("finalNumbers===============End:");
  
          finalNumbers = that.filterNo4(finalNumbers, that.state.hasNo4);
          console.log("hasNo4===============Begin:");
          console.log(finalNumbers);
          console.log("hasNo4===============End:");
  
          console.log("无4过滤器之后 数量："+finalNumbers.length);
          finalNumbers = that.filter3A(finalNumbers, that.state.has3A);

          console.log("has3A===============Begin:");
          console.log(finalNumbers);
          console.log("has3A===============End:");
          console.log("has3A滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filter4A(finalNumbers, that.state.has4A);

          console.log("has4A===============Begin:");
          console.log(finalNumbers);
          console.log("has4A===============End:");
          console.log("has4A滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filterAABB(finalNumbers, that.state);

          console.log("hasAABB===============Begin:");
          console.log(finalNumbers);
          console.log("hasAABB===============End:");
          console.log("AABB滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filterABAB(finalNumbers,  that.state);

          console.log("hasABAB===============Begin:");
          console.log(finalNumbers);
          console.log("hasABAB===============End:");
          console.log("ABAB滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filterABAC(finalNumbers, that.state);

          console.log("filterABAC===============Begin:");
          console.log(finalNumbers);
          console.log("filterABAC===============End:");
          console.log("ABAC滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filterABC(finalNumbers, that.state);

          console.log("filterABC===============Begin:");
          console.log(finalNumbers);
          console.log("filterABC===============End:");
          console.log("ABC滤器之后 数量："+finalNumbers.length);

          
  
          //找到新号码中不在老号码中的
          if(finalNumbers.length>0){
            var  newNumbers = finalNumbers.filter(item => !that.state.numbers.find(x=>x.serialNumber===item.serialNumber));
            console.log("newNumbers===============Begin:");
            console.log(newNumbers);
            console.log("newNumbers===============End:");
            that.setState({
              numbers:[...newNumbers,...that.state.numbers]
            })
            that.setState({
              findCount: that.state.numbers.length
            })
          } 
        }
    
    })
  }, 1000);
  
}

onChangeHasNo4 = (event)=>{
  this.setState({
    hasNo4:!this.state.hasNo4
  });
  //console.log("event.target.value:"+event.target.value)
  //console.log("hasNo4 value:"+ this.state.hasNo4);
}

onChangeHas3A = (event)=>{
  this.setState({
    has3A:!this.state.has3A
  });
  //console.log("event.target.value:"+event.target.value)
  //console.log("has3A value:"+ this.state.has3A);
}

onChangeHas4A = (event)=>{
  this.setState({
    has4A:!this.state.has4A
  });
  //console.log("event.target.value:"+event.target.value)
  //console.log("has3A value:"+ this.state.has3A);
}
onChangeHasAABB = (event)=>{
  this.setState({
    hasAABB:!this.state.hasAABB
  });
}
onChangeHasABAB = (event)=>{
  this.setState({
    hasABAB:!this.state.hasABAB
  });
}
onChangeHasABAC = (event)=>{
  this.setState({
    hasABAC:!this.state.hasABAC
  });
}
onChangeHasABC = (event)=>{
  this.setState({
    hasABC:!this.state.hasABC
  });
}
onChangeHas8Limit = (event)=>{
  this.setState({
    has8Limit:!this.state.has8Limit
  });
}
onChangeHas4Limit = (event)=>{
  this.setState({
    has4Limit:!this.state.has4Limit
  });
}
onCityClick = (cityCode,cityName)=>{
  //console.log("app cityCode:"+cityCode)
  //console.log("app cityName:"+ cityName);
  //event.preventDefault() //阻止表单提交

  this.setState({
    showCity:!this.state.showCity,
    cityCode:cityCode,
    cityName:cityName
  });
}

onProductClick = (goodsId,productName)=>{
  //event.preventDefault() //阻止表单提交
  if("阿里钉钉商务银卡".indexOf(productName)>-1){
    this.setState({
      showProduct:!this.state.showProduct,
      goodsId:'982112279603',
      productName:'银钉卡(收货改套餐）'
    });
    return  ;
  }

  this.setState({
    showProduct:!this.state.showProduct,
    goodsId:goodsId,
    productName:productName
  });
}

onChannelClick = (channelCode,channelName)=>{
  //console.log("app cityCode:"+cityCode)
  //console.log("app cityName:"+ cityName);
  //event.preventDefault() //阻止表单提交
  console.log("channelCode:"+channelCode);
  console.log("channelName:"+channelName);
  this.setState({
    showChannel:!this.state.showChannel,
    channelCode: channelCode,
    channelName: channelName
  });
}

onChooseCityClick = (event)=>{
  event.preventDefault() //阻止表单提交
 
  this.setState({
    showCity:!this.state.showCity
  });
  console.log("this.state.showCity:"+this.state.showCity);
}

onChooseProductClick = (event)=>{
  event.preventDefault() //阻止表单提交
 
  this.setState({
    showProduct:!this.state.showProduct
  });
  console.log("this.state.showProduct:"+this.state.showProduct);

}

onChooseChannelClick = (event)=>{
  event.preventDefault() //阻止表单提交
 
  this.setState({
    showChannel:!this.state.showChannel
  });
  console.log("this.state.showChannel:"+this.state.showChannel);

}

  render() {
 
   return  <div className="App">
          <Container>
          <Breadcrumb>
            <Breadcrumb.Item href={"#/query?u="+this.state.u}>
              查单
            </Breadcrumb.Item>
            <Breadcrumb.Item active>联通智能选号</Breadcrumb.Item>
          </Breadcrumb>

          <div style={{marginBottom:"10px",textAlign:"left",fontSize:"12px"}}>{this.state.productName}</div>
          <div style={{marginBottom:"10px",textAlign:"left",fontSize:"12px"}}>{this.state.tips}</div>

          <div style={{ display: this.state.showCityText?'block':'none'}}>
          <ul className="nav" style={{fontSize:"12px" }}>
              <li className="nav-item">
                归属地  <button  style={{fontSize:"12px"}} type="button" className="btn btn-outline-primary btn-sm" onClick={this.onChooseCityClick}>{this.state.cityName}|切</button> 
              </li>
          </ul>
          </div>
           

          <Modal show={this.state.showCity}  size="lg">
          <Modal.Body>
          <CityLetterList onClick={this.onCityClick}     />
          </Modal.Body>
          </Modal>

          <Modal show={this.state.showProduct}  size="lg">
            <Modal.Body>
            <Container>
              <ProductList onClick={this.onProductClick}  />
            </Container>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.showChannel}  size="lg">
            <Modal.Body>
            <Container>
              <ChannelList onClick={this.onChannelClick}  />
            </Container>
            </Modal.Body>
          </Modal>

          <Form style={{marginTop: '1em'}}>
          <Form.Group controlId="formBasicNumber">
            <Form.Control type="text" placeholder="输入心仪的数字" ref={c => this.number = c}  name="number" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
       
          <Form.Group controlId="formBasicCheckboxHasNo4" className="d-flex p-2 bd-highlight">
            <Form.Check type="checkbox"  label="无4" checked={this.state.hasNo4} style={{marginRight: '1em'}} onChange={this.onChangeHasNo4} />
            <Form.Check type="checkbox"  label="3A" checked={this.state.has3A}  style={{marginRight: '1em'}}    onChange={this.onChangeHas3A} />
            <Form.Check type="checkbox"  label="4A" checked={this.state.has4A}  style={{marginRight: '1em'}}     onChange={this.onChangeHas4A} />
            <Form.Check type="checkbox"  label="ABC" checked={this.state.hasABC}      onChange={this.onChangeHasABC} />
          </Form.Group>

          <Form.Group controlId="formBasicCheckboxHasNo5" className="d-flex p-2 bd-highlight">
            <Form.Check type="checkbox"  label="AABB" checked={this.state.hasAABB}  style={{marginRight: '1em'}}     onChange={this.onChangeHasAABB} />
            <Form.Check type="checkbox"  label="ABAB" checked={this.state.hasABAB} style={{marginRight: '1em'}}     onChange={this.onChangeHasABAB} />
            <Form.Check type="checkbox"  label="ABAC" checked={this.state.hasABAC}     onChange={this.onChangeHasABAC} />
          </Form.Group>

          <Form.Group controlId="formBasicCheckboxHasNo6" className="d-flex p-2 bd-highlight">
            <Form.Check type="checkbox"  label="扫后8位" checked={this.state.has8Limit}  style={{marginRight: '1em'}}     onChange={this.onChangeHas8Limit} />
            <Form.Check type="checkbox"  label="扫后4位" checked={this.state.has4Limit} style={{marginRight: '1em'}}     onChange={this.onChangeHas4Limit} />
 
          </Form.Group>

         <p className="text-center">
          <Button variant="primary"  style={{ display: this.state.scanRun?"none":"block" }}  type="button"  onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block">
            开始扫号
          </Button>

          <Button variant="secondary" style={{ display: this.state.scanRun?"block":"none" }}   type="button"  onClick={this.onStopClick}  className="btn btn-primary btn-lg btn-block">
            停止
          </Button>
          </p>

        </Form>
       
        <div className="d-flex flex-row bd-highlight mb-3">
          <div className="d-inline p-2 bg-primary text-white mt-3">扫描总数：{this.state.totalCount} </div>
          <div className="d-inline p-2 bg-dark text-white mt-3">找到个数：{this.state.findCount} </div>
          <Spinner animation="border" className="mt-3 ml-3" role="status" style={{ display: this.state.scanRun?"block":"none" }}>
          <span className="visually-hidden">号</span>
        </Spinner>
        </div>
        <NumberList numbers={this.state.numbers} channelCode={this.chooseChannelCode()}  u={this.state.u}/>
 
     
    
        <Card body className="mt-3">平台客服</Card>
        <Card body className="mt-3"><img src="haomabaoqrcode.png" style={{width:"100%"}}></img></Card>
        </Container>
       
    </div>
  }
}

export default UnicomScreen;
