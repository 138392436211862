import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class FreeCheScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number,idname,idnum,address} = this
  console.log("可以提交数据了");
 
  if(number.value==="" || !/\d{11}/gi.test(number.value)){
        alert("请输入手机号码！");
        return;
    }
  
    let orderdata = new FormData();
    orderdata.append('sku','电信0元喜福卡');
    orderdata.append('mobile',number.value);
 
 
  axios.post("/lkjapi/tjsc.php",orderdata)
  .then(function (response) {
    console.log(response);
    alert(response.data);
    
  });
}


  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
            <Breadcrumb.Item href="/">
            免费选号
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#/hot">
            天降神车
            </Breadcrumb.Item>

            <Breadcrumb.Item active>自助查询</Breadcrumb.Item>
          </Breadcrumb>

 
          <Card body className="mt-3"> 天降神车自助查询</Card>

          <Card body className="mt-3"> 无需再审核了，加小编13682586395直接参加活动！</Card>


          <Card body className="mt-3">关注撸卡君公众号，回复天降神车，查看完整上车攻略，确认适合自己再上车！</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>
            <img src="product/lkj.jpg" style={{width:"100%"}}/>
            </Card>

          <Form style={{marginTop: '1em'}}>

         

          <Form.Group controlId="formBasicNumber">
            <Form.Control type="text" placeholder="输入手机号码" ref={c => this.number = c}  name="number" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>

 
       
         <p className="text-center">
          <Button variant="primary"  style={{ marginTop: '0.5em' }}  type="button"  onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block">
            自助核实
          </Button>
          </p>

        </Form>
       
        <Card body className="mt-3"> 数据不再同步  如需协助加小编13682586395</Card>

        <Card body className="mt-3"><a href={'#/freecm4?u='+this.state.u}>限时领取移动4元低月租卡</a></Card>

        <Card body className="mt-3"><a href={'#/free4m80g?u='+this.state.u}>0元 80G免费用4个月</a></Card>
     
        </Container>
       
    </div>
  }
}

export default FreeCheScreen;
