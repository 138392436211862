import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class FreeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");
  if(number.value==="" || !/\d{11}/gi.test(number.value)){
    alert("请输入手机号码！");
    return;
}
  axios.get("/robot/qywx.php?msg=视频："+number.value)
  .then(function (response) {
    console.log(response);
    alert("提交成功，预祝好运！");
    number.value="";

  });
}










  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
           
            <Breadcrumb.Item href="#/recharge">
            充50送50
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/?v1">
            免费选号
            </Breadcrumb.Item>
            <Breadcrumb.Item active>免费视频会员</Breadcrumb.Item>
          </Breadcrumb>

          <Card body className="mt-3">免费视频会员,限广东联通号码参与，提交号码后，一个工作日内，发送业务办理邀请！</Card>

          <Form style={{marginTop: '1em'}}>
          <Form.Group controlId="formBasicNumber">
            <Form.Control type="text" placeholder="输入广东联通号码" ref={c => this.number = c}  name="number" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
       
         <p className="text-center">
          <Button variant="primary"  style={{ marginTop: '0.5em' }}  type="button"  onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block">
            确认参与
          </Button>
          </p>

        </Form>
       
        <Card body className="mt-3">活动福利由广东联通提供，如已参加过活动，则不能重复参与。</Card>

        <Card body className="mt-3">办理唐山联通卡，可携号转网到移动成无限卡，详情咨询客服</Card>

        <Card body className="mt-3">移动联通电信卡号套餐合作联系13439975582</Card>
        </Container>
       
    </div>
  }
}

export default FreeScreen;
