import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import Url from 'url';
import FlexProductPanel from './FlexProductPanel'; 


class BigProductScreen extends React.Component {
  constructor(props) {
    super(props);

    console.log(window);
    if(window.location.hostname!=="localhost"){
      //hao.zhongdaiqi.com ->  hmb.zhongdaiqi.com
      if(window.location.hostname==="hao.zhongdaiqi.com"){
          //确保是https
          if(window.location.protocol==="http:"){
            console.log("自动跳转到https 确保用户数据安全");  
            window.location.href=window.location.href.replace("http://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }else{
            window.location.href=window.location.href.replace("https://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }
      }

      //确保是https
      if(window.location.protocol==="http:"){
        console.log("自动跳转到https 确保用户数据安全");  
        window.location.href=window.location.href.replace("http://","https://")
      }
    }

    
    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    
    this.state = {
      u:u
    };

  }

// 页面加在完成
componentDidMount(){
    console.log("智能追踪推广员");
    let finder = Url.parse(this.props.location.search,true); 
    console.log(finder.query);
    if(finder.query.u){
      console.log("推荐员信息存在，重置默认推广员");
      this.setState({u:finder.query.u});
      //推广员默认城市
      if(finder.query.u==='Apathy'){
        this.setState({cityName:'沧州',cityCode:'317'});
      }
    }  
    //默认城市
    
  }

 

  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
            <Breadcrumb.Item href={"#/?u="+this.state.u}>
            免费选号
            </Breadcrumb.Item>
            <Breadcrumb.Item href={"#/cha?u="+this.state.u}>
            查单
            </Breadcrumb.Item>
            <Breadcrumb.Item active>特色卡</Breadcrumb.Item>
          </Breadcrumb>

          <FlexProductPanel u={this.state.u}></FlexProductPanel>

        </Container>
       
    </div>
  }
}

export default BigProductScreen;
