import React from "react";
import { Card } from 'react-bootstrap';
import QueryNumber from './QueryNumber';

class QueryBigList extends React.Component {
  constructor(props) {
    super(props);
  
  }

  render() {
 
    return this.props.numbers.map((li, index) => {
      //console.log(li)
     
      return (
        <Card className="d-inline-flex p-2 bd-highlight mt-1" key={li.orderCode}>
        <Card.Body>
 
        <Card.Text>
           产品:{li.productTitle} <br/>
           状态:{li.orderStatus}<br/>
           快递:{li.express}<br/>
           快递单号:{li.expressNum}<br/>
          </Card.Text>
 
        </Card.Body>
      </Card>



      );
    });
  }
}

export default QueryBigList;