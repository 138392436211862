import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class FreeHotScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");

  axios.get("/phone/real/random")
  .then(function (response) {
    console.log(response);

  });
}


  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
          <Breadcrumb.Item href="/">
            免费选号
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#/hot">
              天降神车
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#/trade">
              神卡转让
            </Breadcrumb.Item>
    

          </Breadcrumb>

          <Card body className="mt-3">天降神车</Card>
         

          <Card body className="mt-3">邂逅官宣定向双不限！阿里钉钉商务银卡！</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>
            <img src="product/lkj.jpg" style={{width:"100%"}}/>
            </Card>

          <Card body className="mt-3"  style={{textAlign:"left"}}>
            <p>步骤一：准备一张联通卡，不限归属地</p>
            <p>步骤二：免费车 自助上车 （如需指导，则需支付车费）</p>
            <p>不愿意花钱的卡友，免费自助上车。需要答疑指导的加客服享受付费服务。</p>
            <p>免费教程给大家写了！如需解答指导，请付费，请理解！</p>
            <p></p>
            <p>阿里钉钉商务银卡通用流量1.5G，过少！套外流量60元/G，巨贵！</p>
            <p>联系小编微信13933393509上车嗨钉卡，33元享20G通用+600分钟通话+钉钉免流双不限，套外流量3元/G！</p>
            <p></p>
            <p>活动规则</p>
            <p> 00、本次上车的是定向双不限，不喜欢定向双不限的不要参与本次活动。</p>
            <p> O1、目前进入公开上车阶段。</p>
            <p> O2、随时可能停车。</p>
            <p> 03、加客服13933393509 索取详细介绍上车介绍！</p>
            <p> 04、为卡友提供了多个下单渠道，以提升下单成功率。</p>
            <p> 05、收货地与归属地一致，下单成功率高 </p>
 
            </Card>
            <Card body className="mt-3"><a href="#/">扫号下单(推荐) </a></Card>
            <Card body className="mt-3"><a href="#/hot01">联通卡下单渠道一</a></Card>
            <Card body className="mt-3"><a href="#/hot02">联通卡下单渠道二</a></Card>
            <Card body className="mt-3"><a href="#/hot03">联通卡下单渠道三 </a></Card>


            <Card body className="mt-3">嗨钉卡33元20G通用+600分钟+钉钉定向双不限 发车中</Card>
        <Card body className="mt-3">移动联通电信卡号套餐合作联系13933393509</Card>
        </Container>
       
    </div>
  }
}

export default FreeHotScreen;
