import React from "react";
import { Card } from 'react-bootstrap';
import QueryNumber from './QueryNumber';

class QueryNumberList extends React.Component {
  constructor(props) {
    super(props);
  
  }

  render() {
    let {onClick} = this.props;
    return this.props.numbers.map((li, index) => {
      //console.log(li)
     
      return (
        <Card className="d-inline-flex p-2 bd-highlight mt-1" key={li.orderNo}>
        <Card.Body>
 
            <QueryNumber index={index} onClick={onClick} orderNo={li.orderNo} phoneNumber={li.selectPhone} />
 
        </Card.Body>
      </Card>



      );
    });
  }
}

export default QueryNumberList;