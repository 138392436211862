import './App.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';
import QueryNumberList from './QueryNumberList';
import QueryOrderFlow from './QueryOrderFlow';



class QueryScreen extends React.Component {
  constructor(props) {
    super(props);
    
    console.log(window);
    if(window.location.hostname!=="localhost"){
      //hao.zhongdaiqi.com ->  hmb.zhongdaiqi.com
      if(window.location.hostname==="hao.zhongdaiqi.com"){
          //确保是https
          if(window.location.protocol==="http:"){
            console.log("自动跳转到https 确保用户数据安全");  
            window.location.href=window.location.href.replace("http://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }else{
            window.location.href=window.location.href.replace("https://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }
      }

      //确保是https
      if(window.location.protocol==="http:"){
        console.log("自动跳转到https 确保用户数据安全");  
        window.location.href=window.location.href.replace("http://","https://")
      }
    }
 
    this.handleSubmit = this.handleSubmit.bind(this);
    this.queryNumberClick = this.queryNumberClick.bind(this);
    
    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    this.state = {
      numbers:[],
      orderFlow:[],
      u:u
    };
}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number,idnum} = this
  var that = this;
  console.log("可以提交数据了");
 
  if(number.value==="" || !/\d{11}/gi.test(number.value)){
        alert("请输入手机号码！");
        return;
    }
  
  if(idnum.value==="" ){
    alert("请输入证件号码！");
    return;
   }
  
 

    let orderdata = new FormData();
    orderdata.append('certNum',idnum.value);
    orderdata.append('contactNum',number.value);
 
  axios.post("/v2/api/getLastFiveOrder",orderdata)
  .then(function (response) {
    console.log(response);
    console.log(response.data.success);
    if(response.data.success){
      that.setState({numbers:response.data.data});
    }else{
      alert("查不到，这是扫号查询，不是特色卡查询！请尝试特色卡查询！");
    }
   


  });
}


queryNumberClick = (orderNo,queryNumber)=>{
  console.log("app orderNo:"+orderNo)
  console.log("app queryNumber:"+ queryNumber);
  //event.preventDefault() //阻止表单提交

  // this.setState({
  //   showCity:!this.state.showCity,
  //   cityCode:cityCode,
  //   cityName:cityName
  // });
  var that = this;
  let orderdata = new FormData();
  orderdata.append('orderNo',orderNo);

  axios.post("/v2/api/getOrderFlowInfo",orderdata)
  .then(function (response) {
    console.log(response);
    console.log(response.data.success);
    if(response.data.success){
      that.setState({orderFlow:response.data.data});
    }else{
      alert(response.data.message);
    }
  


  });
}


  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
            <Breadcrumb.Item href={"#/?u="+this.state.u}>
            免费选号
            </Breadcrumb.Item>
 
   
  
            <Breadcrumb.Item active>智能扫号订单查询</Breadcrumb.Item>
          </Breadcrumb>

          <Form>

          <Card body className="mt-3"> 联通智能扫号订单查询 </Card>
          <Card body className="mt-3"> 此功能仅提供联通智能扫号订单查询！如查询不到订单，说明你的订单不是智能扫号订单，请进入特色卡查询界面查询。<a href={"#/cha?u="+this.state.u}>点此查询特色卡订单</a></Card>

          <Form.Group controlId="formBasicIdNum" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="输入身份证号" ref={c => this.idnum = c}  name="idnum" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>



          <Form.Group controlId="formBasicNumber" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="输入联系号码" ref={c => this.number = c}  name="number" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>

  
         <p className="text-center">
          <Button variant="primary"  style={{ marginTop: '0.5em' }}  type="button"  onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block">
            扫号订单查询 
          </Button>
          </p>

      
         
        </Form>



      
        <p>
           <QueryNumberList numbers={this.state.numbers} onClick={this.queryNumberClick} />
       </p>

        <p>
           <QueryOrderFlow orderFlow={this.state.orderFlow}  />
        </p>

        <Card body className="mt-3"> 如非扫号订单，<a href={"#/cha?u="+this.state.u}>点此查询特色卡</a></Card>
        <Card body className="mt-3"> 如有查询问题，请联系代理商或平台客服</Card>
      
        </Container>
       
    </div>
  }
}

export default QueryScreen;
