import React from "react";
import { Form,  Button , Card ,Container,Modal,Spinner } from 'react-bootstrap';


class VipCity extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick = (city)=>{
    console.log("city被点击");
    console.log(city);
    console.log(city.target);
    console.log(city.target.value);
    console.log(this.props.cityCode);
    console.log(this.props.cityName);
    this.props.onClick(this.props.cityCode,this.props.cityName);
  }

  render() {
    let {index,cityName} = this.props;
    let {onClick} = this;
    return (
        <li key={index}>
              <Form.Check 
                    type='checkbox'
                    id={`default-checkbox-${index}`}
                    label={`${cityName}`}
                    onChange={onClick}
                />
          
        </li>
    );
  }
}

export default VipCity;