import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';
import NumberList from './NumberList';
import VipCityLetterList from './VipCityLetterList';
import ProductList from './ProductList';
 
class VipScreen extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      clickCount: 0,numbers:[],hasNo4:true,
      has3A:false,totalCount:0,
      findCount:0,counter:0,
      showCity:false,
      showProduct:false,
      cityName:'唐山',
      cityCode:181,
      scanRun:false,
      goodsId:'981610241535',
      productName:'大王卡',
      vipCityList:[]
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeHasNo4 = this.onChangeHasNo4.bind(this);
    this.onChangeHas3A = this.onChangeHas3A.bind(this);
    this.onCityClick = this.onCityClick.bind(this);
    this.onProductClick = this.onProductClick.bind(this);
    this.onChooseCityClick = this.onChooseCityClick.bind(this);
    this.onChooseProductClick = this.onChooseProductClick.bind(this);
    this.onStopClick = this.onStopClick.bind(this);
    this.job =null;
}

filterKeyNumber = (nList,keyNumber)=>{
  console.log("keyNumber:"+keyNumber.value);
  if(keyNumber.value===''){
    return nList;
  }
  return nList.filter((item)=>{
    console.log("keyNumber.value:"+item.serialNumber.indexOf(keyNumber.value)>-1)
    return item.serialNumber.indexOf(keyNumber.value)>-1
  })
}

filterNo4 = (nList,runFilter)=>{
  if(!runFilter){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  return nList.filter((item)=>{return item.serialNumber.indexOf(4)===-1})
}

filter3A = (nList,runFilter)=>{
  if(!runFilter){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  return nList.filter((item)=>{ 
        return item.serialNumber.indexOf('111')>-1
      ||item.serialNumber.indexOf('222')>-1
      ||item.serialNumber.indexOf('333')>-1
      ||item.serialNumber.indexOf('444')>-1
      ||item.serialNumber.indexOf('555')>-1
      ||item.serialNumber.indexOf('666')>-1
      ||item.serialNumber.indexOf('777')>-1
      ||item.serialNumber.indexOf('888')>-1
      ||item.serialNumber.indexOf('999')>-1
      ||item.serialNumber.indexOf('000')>-1}
  )
}

chooseUrl = (number,city,goodsId,that)=>{
  if(number.length>4){
      console.log("超过4位，无需传数字")
      return "/phone/real/random?belongCity="+city+'&goodsId='+goodsId;
  }
  console.log("4位以内，需要摆动")
  console.log("counter:"+ that.state.counter % 2);
  that.setState( {counter:that.state.counter+1 })
  if(that.state.counter % 2 === 1){
    return "/phone/real/random?belongCity="+city+"&keyNum="+number.value+'&goodsId='+goodsId;
  }
  return "/phone/real/random?belongCity="+city+'&goodsId='+goodsId;
}
onStopClick = (event)=>{
  event.preventDefault() //阻止表单提交
  this.setState({
    scanRun:false
  });
  if(this.job!=null){
    clearInterval(this.job);
  }

}
handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  var that = this
  console.log("hasNo4 value:"+ this.state.hasNo4);
  console.log("has3A value:"+ this.state.has3A);
  that.setState({
    scanRun:true
  });
  if(this.job!=null){
    clearInterval(this.job);
    that.setState({
      clickCount: 0,numbers:[],totalCount:0,
      findCount:0,counter:0,
      showCity:false
    });
  }
  this.job = setInterval(() => {
    axios.get( that.chooseUrl(number,that.state.cityCode,that.state.goodsId,that) )
    .then(function (response) {
        console.log("request done");
        if(response.data.success ){
          console.log(response.data.data.randomList);
          that.setState({
            totalCount: that.state.totalCount+response.data.data.randomList.length
          })
          var  finalNumbers = that.filterKeyNumber(response.data.data.randomList,number);
          console.log("finalNumbers===============Begin:");
          console.log(finalNumbers);
          console.log("finalNumbers===============End:");
  
          finalNumbers = that.filterNo4(finalNumbers, that.state.hasNo4);
          console.log("hasNo4===============Begin:");
          console.log(finalNumbers);
          console.log("hasNo4===============End:");
  
          console.log("无4过滤器之后 数量："+finalNumbers.length);
          finalNumbers = that.filter3A(finalNumbers, that.state.has3A);
  
          console.log("has3A===============Begin:");
          console.log(finalNumbers);
          console.log("has3A===============End:");
  
          console.log("3A滤器之后 数量："+finalNumbers.length);
          //找到新号码中不在老号码中的
          if(finalNumbers.length>0){
            var  newNumbers = finalNumbers.filter(item => !that.state.numbers.find(x=>x.serialNumber===item.serialNumber));
            console.log("newNumbers===============Begin:");
            console.log(newNumbers);
            console.log("newNumbers===============End:");
            that.setState({
              numbers:[...newNumbers,...that.state.numbers]
            })
            that.setState({
              findCount: that.state.numbers.length
            })
          } 
        }
    
    })
  }, 1000);
  
}

onChangeHasNo4 = (event)=>{
  this.setState({
    hasNo4:!this.state.hasNo4
  });
  //console.log("event.target.value:"+event.target.value)
  //console.log("hasNo4 value:"+ this.state.hasNo4);
}

onChangeHas3A = (event)=>{
  this.setState({
    has3A:!this.state.has3A
  });
  //console.log("event.target.value:"+event.target.value)
  //console.log("has3A value:"+ this.state.has3A);
}
onCityClick = (cityCode,cityName)=>{
  console.log("app cityCode:"+cityCode)
  console.log("app cityName:"+ cityName);
  //event.preventDefault() //阻止表单提交
  console.log(this.state.vipCityList);
  var filteredElements = this.state.vipCityList.filter(function(item, index) { return item.cityCode == cityCode ;});
  console.log("城市查找结果：");
  console.log(filteredElements);
  if (filteredElements.length<=0) {
    console.log("城市不存在，需要添加");
    var newVipCityList = [...this.state.vipCityList];
    newVipCityList.push({cityCode:cityCode,cityName:cityName});
    this.setState({
      vipCityList:newVipCityList
    });
    console.log("城市添加后结果:");
    console.log(newVipCityList);
  }else{
    console.log("城市已经存在，需要删除");
    var newVipCityList = this.state.vipCityList.filter(function(item) {  return item.cityCode != cityCode ;});
    this.setState({
       vipCityList:newVipCityList
     });
     console.log("城市删除后结果:");
     console.log(newVipCityList);

  }
 
}

onProductClick = (goodsId,productName)=>{
  //console.log("app cityCode:"+cityCode)
  //console.log("app cityName:"+ cityName);
  //event.preventDefault() //阻止表单提交
  this.setState({
    showProduct:!this.state.showProduct,
    goodsId:goodsId,
    productName:productName
  });
}

onChooseCityClick = (event)=>{
  event.preventDefault() //阻止表单提交
 
  this.setState({
    showCity:!this.state.showCity
  });
  console.log("this.state.showCity:"+this.state.showCity);
}

onChooseProductClick = (event)=>{
  event.preventDefault() //阻止表单提交
 
  this.setState({
    showProduct:!this.state.showProduct
  });
  console.log("this.state.showProduct:"+this.state.showProduct);

}

  render() {
 
   return  <div className="App">
          <Container>
          <ul className="nav">
              <li className="nav-item">
                  <button  type="button" className="btn btn-outline-primary btn-sm" onClick={this.onChooseCityClick}>{this.state.cityName}|切换</button> 
              </li>
              <li className="nav-item" style={{marginLeft: '0.5em'}}>
              <button  type="button" className="btn btn-outline-primary btn-sm" onClick={this.onChooseProductClick} >{this.state.productName}|切换</button> 
              </li>
          </ul>
 

          <Modal show={this.state.showCity}  size="lg">
          <Modal.Body>
              <VipCityLetterList onClick={this.onCityClick}     />
          </Modal.Body>
          </Modal>

          <Modal show={this.state.showProduct}  size="lg">
            <Modal.Body>
            <Container>
              <ProductList onClick={this.onProductClick}  />
            </Container>
            </Modal.Body>
          </Modal>

          <Form style={{marginTop: '1em'}}>
          <Form.Group controlId="formBasicNumber">
            <Form.Control type="text" placeholder="输入心仪的数字" ref={c => this.number = c}  name="number" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
       
          <Form.Group controlId="formBasicCheckboxHasNo4" className="d-flex p-2 bd-highlight">
            <Form.Check type="checkbox"  label="无4" checked={this.state.hasNo4} style={{marginRight: '2em'}} onChange={this.onChangeHasNo4} />
            <Form.Check type="checkbox"  label="3A" checked={this.state.has3A}     onChange={this.onChangeHas3A} />
          </Form.Group>

         <p className="text-center">
          <Button variant="primary"  style={{ display: this.state.scanRun?"none":"block" }}  type="button"  onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block">
            开始选号
          </Button>

          <Button variant="secondary" style={{ display: this.state.scanRun?"block":"none" }}   type="button"  onClick={this.onStopClick}  className="btn btn-primary btn-lg btn-block">
            停止
          </Button>
          </p>

        </Form>
       
        <div className="d-flex flex-row bd-highlight mb-3">
          <div className="d-inline p-2 bg-primary text-white mt-3">扫描总数：{this.state.totalCount} </div>
          <div className="d-inline p-2 bg-dark text-white mt-3">找到个数：{this.state.findCount} </div>
          <Spinner animation="border" className="mt-3 ml-3" role="status" style={{ display: this.state.scanRun?"block":"none" }}>
          <span className="visually-hidden">号</span>
        </Spinner>
        </div>
        <NumberList numbers={this.state.numbers} />

        <Card body className="mt-3">默认套餐为联通大王卡，扫到喜欢的号码及时下单，避免被他人抢先。如需全国范围自动扫，使用<a href="#/vip">高级版本</a>。</Card>

        <Card body className="mt-3">办理唐山联通卡，可携号转网到移动成无限卡，详情咨询客服</Card>

        <Card body className="mt-3">移动联通电信卡号套餐合作联系13439975582</Card>
        </Container>
       
    </div>
  }
}


export default VipScreen;
