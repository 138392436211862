import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class ProductZhiShi extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");

  axios.get("/phone/real/random")
  .then(function (response) {
    console.log(response);

  });
}


  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
          <Breadcrumb.Item href="/">
            免费选号
            </Breadcrumb.Item>
          <Breadcrumb.Item href="#/free">
          免费视频会员
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#/recharge">
            充50送50
            </Breadcrumb.Item>
    

          </Breadcrumb>

          <Card body className="mt-3">芝士卡下单码</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>
            <img src="product/zhishi.jpg" style={{width:"100%"}}/>
           
            </Card>

        <Card body className="mt-3">如需扫芝士卡号码，选择其他卡种扫号，再用后4位定位号码</Card>

        <Card body className="mt-3">如下单失败，请联系客服排查问题</Card>

        <Card body className="mt-3">移动联通电信卡号套餐合作联系13439975582</Card>
        </Container>
       
    </div>
  }
}

export default ProductZhiShi;
