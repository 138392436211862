import './App.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';
import QueryBigList from './QueryBigList';
 



class QueryBigScreen extends React.Component {
  constructor(props) {
    super(props);

    console.log(window);
    if(window.location.hostname!=="localhost"){
      //hao.zhongdaiqi.com ->  hmb.zhongdaiqi.com
      if(window.location.hostname==="hao.zhongdaiqi.com"){
          //确保是https
          if(window.location.protocol==="http:"){
            console.log("自动跳转到https 确保用户数据安全");  
            window.location.href=window.location.href.replace("http://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }else{
            window.location.href=window.location.href.replace("https://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }
      }

      //确保是https
      if(window.location.protocol==="http:"){
        console.log("自动跳转到https 确保用户数据安全");  
        window.location.href=window.location.href.replace("http://","https://")
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this);
 
    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    
    this.state = {
      numbers:[],
      u:u
    };

}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number,idnum} = this
  var that = this;
  console.log("可以提交数据了");
 
  if(number.value==="" || !/\d{11}/gi.test(number.value)){
        alert("请输入手机号码！");
        return;
    }
  
  if(idnum.value==="" ){
    alert("请输入证件号码！");
    return;
   }
  
 

    let orderdata = new FormData();
    orderdata.append('idNum',idnum.value);
    orderdata.append('phone',number.value);
 
  axios.post("/hopeapi/order/cha",orderdata)
  .then(function (response) {
    console.log(response);
    if(response.data.status){
      that.setState({numbers:response.data.data});
    }else{
      alert(response.data.message);
    }
   


  });
}

  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
            <Breadcrumb.Item href={"#/?u="+this.state.u}>
            免费选号
            </Breadcrumb.Item>
 

            <Breadcrumb.Item active>订单查询</Breadcrumb.Item>
          </Breadcrumb>

          <Card body className="mt-3"> 特色卡订单查询 </Card>
          <Card body className="mt-3"> 此功能仅提供特色卡订单查询！如查询不到订单，说明你的订单不是特色卡订单，请进入联通智能扫号订单界面查询。<a href={"#/query?u="+this.state.u}>点此查询联通智能扫号订单</a></Card>



          <Form>

    

          <Form.Group controlId="formBasicIdNum" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="输入身份证号" ref={c => this.idnum = c}  name="idnum" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>



          <Form.Group controlId="formBasicNumber" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="输入联系号码" ref={c => this.number = c}  name="number" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>

  
         <p className="text-center">
          <Button variant="primary"  style={{ marginTop: '0.5em' }}  type="button"  onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block">
            查询特色卡订单
          </Button>
          </p>
         
        </Form>
        <p>
             <QueryBigList numbers={this.state.numbers}   />
       
             <Card body className="mt-3"> 如非特色卡订单，<a href={"#/query?u="+this.state.u}>点此查询智能扫号订单</a></Card>
        <Card body className="mt-3"> 如有查询问题，请联系代理商或平台客服</Card>
          </p>
        </Container>
       
    </div>
  }
}

export default QueryBigScreen;
