import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class Free10000Screen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");

  axios.get("/phone/real/random")
  .then(function (response) {
    console.log(response);

  });
}










  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
          <Breadcrumb.Item href="/">
            免费选号
            </Breadcrumb.Item>
          <Breadcrumb.Item href="#/free">
          免费视频会员
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#/recharge">
            充50送50
            </Breadcrumb.Item>
    

          </Breadcrumb>

          <Card body className="mt-3">电信50充50教程</Card>
          <Card body className="mt-3"  style={{textAlign:"left"}}>
            <p>步骤一：下载电信营业厅APP</p>
            <p>步骤二：登陆进入首页找到领权益</p>
            <p>步骤三：在“精彩活动”找到High盲盒抽奖活动</p>
            <p>步骤四：充值50元参与活动</p>
            <p>活动规则</p>
            <p>

            O1、您需一次性预存50元话费，从预存当月起，每月将返还您5元话费，连续返还10个月。   </p>

            <p> O2、您购买盲盒成功后，当月即可抽奖，每个月可在当月月底最后一天19:00前抽取一次盲盒奖品，100%中奖!最多可连续10个月，每月抽奖1次，共抽10次。其中有不少于2次您将必中10G全国流量。</p>

            <p> 03、盲盒内礼品包含:200元味多美代金券、爱奇艺VIP月卡、优酷视频VIP月卡、腾讯视频VIP月卡、10G全国流量、100分钟全国语音、100条全国短信、1G全国流量、通信助理体验包等诸多权益。

            </p>
            <p>小编使用北京电信号成功参与，其他地区可尝试！</p>
            <p>如活动已下线，可通知小编同步活动状态！</p>
            </Card>

        <Card body className="mt-3">办理唐山联通卡，可携号转网到移动成无限卡，详情咨询客服</Card>

        <Card body className="mt-3">移动联通电信卡号套餐合作联系13439975582</Card>
        </Container>
       
    </div>
  }
}

export default Free10000Screen;
